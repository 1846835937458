import { getUserWishlists } from "@/api/mongoWishlists.api";

const state = {
  showCreateWishlistModal: false,
  newWishlistTracks: [],
  wishlists: [],
};

const getters = {
  getShowCreateWishlistModal(state) {
    return state.showCreateWishlistModal;
  },
  getNewWishlistTracks(state) {
    return state.newWishlistTracks;
  },
  getWishlists(state) {
    return state.wishlists;
  },
  getWishlistById: (state) => (wishlistId) => {
    return state.wishlists.find((wishlist) => wishlist._id === wishlistId);
  },
};

const actions = {
  async fetchWishlists({ commit }) {
    try {
      const { data } = await getUserWishlists();
      commit("SET_WISHLISTS", data && data.length > 0 ? data : []);
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  SET_SHOW_CREATE_WISHLIST_MODAL(state, payload) {
    state.showCreateWishlistModal = payload;
  },
  SET_NEW_WISHLIST_TRACKS(state, payload) {
    state.newWishlistTracks = payload;
  },
  SET_WISHLISTS(state, payload) {
    state.wishlists = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
