const AuthModule = {
  namespaced: true,
  state: () => ({
    user: null,
    isLoggedIn: false,
  }),
  getters: {
    getUser: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.isLoggedIn;
    },
  },
  mutations: {
    signIn(state, userData) {
      state.user = userData;
      state.isLoggedIn = true;
    },
    signOut(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
  actions: {
    signIn({ commit }, userData) {
      commit("signIn", userData);
    },
    signOut({ commit }) {
      commit("signOut");
    },
  },
};

export default AuthModule;
