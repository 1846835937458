import {
  chunkArrayIntoEqualSizes,
  sumArrayElements,
} from "@/utils/staticHelper";

const state = {
  baseForecastConfigurationPercentages: null,
  forecastLastYearRevenue: null,
  baseForecastRevenuesSum: [],
  baseForecastMarketGrowth: [],
  maxMarketingBudgets: 0,
  marketingForecasts: [],
  updateListenersTrendGrowthStatus: false,
};

const getters = {
  getBaseForecastConfigurationPercentages(state) {
    return state.baseForecastConfigurationPercentages;
  },
  getForecastLastYearRevenue(state) {
    return state.forecastLastYearRevenue;
  },
  getBaseForecastRevenuesSum(state) {
    return state.baseForecastRevenuesSum;
  },
  getBaseForecastMarketGrowth(state) {
    return state.baseForecastMarketGrowth;
  },
  getMaxMarketingBudgets(state) {
    return state.maxMarketingBudgets;
  },
  getMarketingForecasts(state) {
    return state.marketingForecasts;
  },
  getUpdateListenersTrendGrowthStatus(state) {
    return state.updateListenersTrendGrowthStatus;
  },
};

const actions = {
  setForecastLastYearRevenue({ commit }, payload) {
    commit("SET_FORECAST_LAST_YEAR_REVENUE", payload);
  },
  setBaseForecastRevenues({ commit }, array) {
    if (array.length) {
      commit("SET_BASE_FORECAST_MARKET_GROWTH", array);
      const dividedArray = chunkArrayIntoEqualSizes(array, 4);
      const summedArray = [];
      for (const data of dividedArray) {
        summedArray.push(sumArrayElements(data));
      }
      commit("SET_BASE_FORECAST_REVENUES", summedArray);
    }
  },
  setMaxMarketingBudgets({ commit }, payload) {
    commit("SET_MAX_MARKETING_BUDGETS", payload);
  },
  setMarketingForecasts({ commit }, payload) {
    commit("SET_MARKETING_FORECAST", payload);
  },
  setUpdateListenersTrendGrowthStatus({ commit }, payload) {
    commit("SET_UPDATE_LISTENERS_TREND_GROWTH_STATUS", payload);
  },
  setBaseForecastConfigurationValues({ commit }, payload) {
    commit("SET_BASE_FORECAST_CONFIGURATION_PERCENTAGES", payload);
  },
};

const mutations = {
  SET_BASE_FORECAST_CONFIGURATION_PERCENTAGES(state, payload) {
    state.baseForecastConfigurationPercentages = payload;
  },
  SET_FORECAST_LAST_YEAR_REVENUE(state, payload) {
    state.forecastLastYearRevenue = payload;
  },
  SET_BASE_FORECAST_REVENUES(state, payload) {
    state.baseForecastRevenuesSum = payload;
  },
  SET_BASE_FORECAST_MARKET_GROWTH(state, payload) {
    state.baseForecastMarketGrowth = payload;
  },
  SET_MAX_MARKETING_BUDGETS(state, payload) {
    state.maxMarketingBudgets = payload;
  },
  SET_MARKETING_FORECAST(state, payload) {
    state.marketingForecasts = payload;
  },
  SET_UPDATE_LISTENERS_TREND_GROWTH_STATUS(state, payload) {
    state.updateListenersTrendGrowthStatus = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
