import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import { getPlatformColor } from "@/utils/staticHelper";

const state = {
  totalFanbase: 0,
  lastYearRevenues: 0,
  watchListModalArtistUUID: "",
  artistUUID: null,
  spotifyListenersObject: {
    data: [],
    name: "Spotify Listeners",
    type: "line",
  },
};

const getters = {
  getUUIDToShowWatchListModal(state) {
    return state.watchListModalArtistUUID;
  },
  getTotalFanbase(state) {
    return state.totalFanbase;
  },
  getLastYearReveneus(state) {
    return state.lastYearRevenues;
  },
  getArtistUUID(state) {
    return state.artistUUID;
  },
  getSpotifyListenersObject(state) {
    return state.spotifyListenersObject;
  },
};

const actions = {
  setFanbase({ commit }, totalFanbase) {
    commit("SET_FANBASE", totalFanbase);
  },
  setLastYearRevenues({ commit }, revenue) {
    commit("SET_LAST_YEAR_REVENUE", revenue);
  },
  setArtistUUID({ commit }, uuid) {
    commit("SET_ARTIST_UUID", uuid);
  },
  async getArtistListeners({ commit }, payload) {
    try {
      const { data } = await getFanmetricSourcesBySpotifyID(
        payload.spotifyID,
        "listeners",
        "spotify",
        payload.years,
        1
      );
      const listeners = [];
      for (const listener of data) {
        listeners.push(listener.value ? listener.value : 0);
      }
      const listenersObject = {
        data: listeners,
        name: "Spotify Listeners",
        type: "line",
        color: getPlatformColor("spotify"),
      };
      commit("SET_SPOTIFY_LISTENERS", listenersObject);
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID(state, payload) {
    state.watchListModalArtistUUID = payload;
  },
  SET_FANBASE(state, totalFanbase) {
    state.totalFanbase = totalFanbase;
  },
  SET_LAST_YEAR_REVENUE(state, revenue) {
    state.lastYearRevenues = revenue;
  },
  SET_ARTIST_UUID(state, uuid) {
    state.artistUUID = uuid;
  },
  SET_SPOTIFY_LISTENERS(state, payload) {
    state.spotifyListenersObject = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
