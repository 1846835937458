import useSpotifyApi from "../../common/composables/useSpotifyApi";
import store from "../store";

const state = {
  filterData: {
    genre: null,
  },
  searchResults: {
    loading: false,
    listeners: {
      results: {
        artists: [],
      },
      pagination: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    },
    artists: {
      pagination: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      results: [],
    },
  },
};

const getters = {
  getAIParsedData(state) {
    return state.searchResults.listeners.results;
  },
  getSearchedArtists(state) {
    return state.searchResults.artists.results;
  },
  getSearchedArtistsPagination(state) {
    return state.searchResults.artists.pagination;
  },
  getSearchedListenersPagination(state) {
    return state.searchResults.listeners.pagination;
  },
  getSearchedResultLoading(state) {
    return state.searchResults.loading;
  },
  getFilterDataGenre(state) {
    return state.filterData.genre;
  },
};

const mutations = {
  SET_SEARCHED_ARTISTS(state, payload) {
    state.searchResults.artists.results = [];
    state.searchResults.artists.results = payload;
  },
  SET_SEARCHED_ARTISTS_PAGINATION(state, payload) {
    state.searchResults.artists.pagination = payload;
  },
  SET_SEARCHED_LISTENERS_PAGINATION(state, payload) {
    state.searchResults.listeners.pagination = payload;
  },
  SET_SEARCHED_RESULTS_LOADING(state, payload) {
    state.searchResults.loading = payload;
  },
  SET_FILTER_DATA_GENRE(state, payload) {
    state.filterData.genre = payload;
  },
  SET_AI_PARSED_DATA(state, payload) {
    state.searchResults.listeners.results = payload;
  },
  CLEAR_SEARCH_DATA(state) {
    state.searchResults = {
      loading: false,
      listeners: {
        results: {
          artists: [],
        },
        pagination: {
          offset: 1,
          limit: 10,
          total: 0,
        },
      },
      artists: {
        pagination: {
          offset: 1,
          limit: 10,
          total: 0,
        },
        results: [],
      },
    };
  },
  CLEAR_SEARCH_LISTENERS(state) {
    state.searchResults.listeners = {
      results: {
        artists: [],
      },
      pagination: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    };
  },
};

const actions = {
  async fetchArtistsByGenre({ state, commit }) {
    if (state.filterData.genre) {
      commit("SET_SEARCHED_RESULTS_LOADING", true);
      try {
        const spotifyApi = await useSpotifyApi(store);
        const {
          body: { artists },
        } = await spotifyApi.search(
          `genre:"${state.filterData.genre}"`,
          ["artist"],
          {
            limit: state.searchResults.artists.pagination.limit,
            offset:
              (state.searchResults.artists.pagination.offset - 1) *
              state.searchResults.artists.pagination.limit,
          }
        );
        if (artists.items && artists.items.length > 0) {
          commit("SET_SEARCHED_ARTISTS", artists.items);
          commit("SET_SEARCHED_ARTISTS_PAGINATION", {
            offset: artists.offset / artists.limit + 1,
            limit: artists.limit,
            total: artists.total,
          });
        } else {
          commit("SET_SEARCHED_ARTISTS", []);
          commit("SET_SEARCHED_ARTISTS_PAGINATION", {
            offset: 1,
            limit: state.searchResults.artists.pagination.limit,
            total: 0,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("SET_SEARCHED_RESULTS_LOADING", false);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
