import httpClient from "./httpClient";
import wishlists from "@/wishlist/views/Wishlists.vue";

const WISHLIST = "wishlist";

const getUserWishlists = (wishlistID = "") =>
  httpClient.get(wishlistID ? `${WISHLIST}/${wishlistID}` : `${WISHLIST}`);

const getWishlistTracksStats = (wishlistID) =>
  httpClient.get(`${WISHLIST}/track-stats/${wishlistID}`);

const getWishlistWeightedListeners = (wishlistID) =>
  httpClient.get(`${WISHLIST}/weighted-listeners/${wishlistID}`);

const getWishlistWeightedFollowers = (wishlistID) =>
  httpClient.get(`${WISHLIST}/weighted-followers/${wishlistID}`);

const getWishlistWeightedPlaylistEvolution = (wishlistID) =>
  httpClient.get(`${WISHLIST}/weighted-playlists/${wishlistID}`);

const getTrackPlaylistEvolution = (wishlistID) =>
  httpClient.get(`${WISHLIST}/track-playlist-evolution/${wishlistID}`);

const getWhereArtistListeners = (wishlistID) =>
  httpClient.get(`${WISHLIST}/where-artist-listeners/${wishlistID}`);

const getFundsByWishlistID = (wishlistID) =>
  httpClient.get(`${WISHLIST}/funds/${wishlistID}`);

const updateWishlistArtists = (payload) =>
  httpClient.post(`${WISHLIST}/update-wishlist-artists`, payload);

const createUserTracksWishlist = (payload) =>
  httpClient.post(`${WISHLIST}`, payload);

const updateUserTracksWishlist = (payload) =>
  httpClient.put(`${WISHLIST}`, payload);

const updateFundSlotByWishlistID = (wishlistID, payload) =>
  httpClient.put(`${WISHLIST}/${wishlistID}`, payload);

const deleteUserWishlist = (wishlistID) =>
  httpClient.delete(`${WISHLIST}/${wishlistID}`);

const addTracksInWishlist = (payload) =>
  httpClient.put(`${WISHLIST}/add-tracks`, payload);

const removeTracksFromWishlist = (payload) =>
  httpClient.put(`${WISHLIST}/remove-tracks`, payload);

export {
  getUserWishlists,
  getWishlistTracksStats,
  getWishlistWeightedListeners,
  getWishlistWeightedFollowers,
  getWishlistWeightedPlaylistEvolution,
  getTrackPlaylistEvolution,
  getWhereArtistListeners,
  getFundsByWishlistID,
  updateWishlistArtists,
  createUserTracksWishlist,
  updateUserTracksWishlist,
  updateFundSlotByWishlistID,
  deleteUserWishlist,
  addTracksInWishlist,
  removeTracksFromWishlist,
};
