import $ from "jquery";
import store from "@/store/store";

const state = {
  timeOuts: [],
  player: {},
  playerData: {},
  isPlayedAble: true,
  loading: false,
};

const getters = {
  getPlayer(state) {
    return state.player;
  },
  getPlayerData(state) {
    return state.playerData;
  },
  getPlayerLoading(state) {
    return state.loading;
  },
};

const mutations = {
  ADD_TIMER_TO_ARRAY(state, payload) {
    state.timeOuts.push(payload);
  },
  SET_PLAYER_DATA(state, payload) {
    state.playerData = payload;
  },
  SET_IS_PLAY_ABLE(state, payload) {
    state.isPlayedAble = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  CREATE_PLAYER(state) {
    state.player = new Audio();
  },
  EMPTY_TIMER_ARRAY(state) {
    state.timeOuts = [];
  },
};

const actions = {
  async createPlayer({ commit }) {
    await commit("CREATE_PLAYER");
  },
  async setIsPlayAble({ commit }, payload) {
    await commit("SET_IS_PLAY_ABLE", payload);
  },
  setLoadingOfPlayer({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  clearTimeOuts({ state, commit }) {
    state.timeOuts.forEach((timer) => {
      clearTimeout(timer);
    });
    commit("EMPTY_TIMER_ARRAY");
  },
  async playSong(
    { state, commit },
    { uuid, artistName, albumName, imageUrl, trackPreviewUrl }
  ) {
    if (state.isPlayedAble) {
      commit("SET_PLAYER_DATA", {});
      commit("SET_PLAYER_DATA", { artistName, albumName, imageUrl });
      commit("SET_LOADING", false);
      try {
        state.player.pause();
      } catch (e) {
        //
      } finally {
        state.player.src = trackPreviewUrl;
        await state.player.load();
        try {
          const element = $(`#${uuid}`);
          if (element && element.is(":hover")) {
            try {
              await state.player.play();
            } catch (e) {
              //
            }
          } else {
            await commit("SET_PLAYER_DATA", {});
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      //
    }
  },
  async setTimeOutToPlaySong({ commit, dispatch }, callback) {
    await dispatch("clearTimeOuts");
    const index = setTimeout(async () => {
      await commit("SET_LOADING", true);
      const { uuid, artistName, albumName, imageUrl, trackPreviewUrl } =
        await callback(store);
      await dispatch("playSong", {
        uuid,
        artistName,
        albumName,
        imageUrl,
        trackPreviewUrl,
      });
      await commit("SET_LOADING", false);
    }, 500);
    await commit("ADD_TIMER_TO_ARRAY", index);
  },
  async stopSong({ state, commit, dispatch }) {
    try {
      await dispatch("clearTimeOuts");
      await commit("SET_PLAYER_DATA", {});
      await commit("SET_LOADING", false);
      await state.player.pause();
    } catch (e) {
      //
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
