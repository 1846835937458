import httpClient from "@/api/httpClient";

// const BASE_URL = "https://blink-rock-crawler.herokuapp.com/api";

const getGenreResult = () => httpClient.get(`/csv`);

const MONGO_GENRE = "/mongo-genre";
const MONGO_CHARTMETRIC_GENRE = "/mongo-chartmetric-genres";

const getAllGenresFromMongo = () => httpClient.get(`${MONGO_GENRE}/all-genres`);

const getAllGenres = () => httpClient.get(`spotify/all-genres`);

const getGenreDetailFromMongoV2 = (url, genre) =>
  httpClient.get(`${url}/genre-detail?genre=${genre}`);

const getGenreListenersGrowthV2 = (url, genre) =>
  httpClient.get(`${url}/listeners-growth-genre?genre=${genre}`);

const getGenreListenerBenchmarksV2 = (url, genre) =>
  httpClient.get(`${url}/genre-listener-benchmarks?genre=${genre}`);

const getGenreStreamsV2 = (url, genre) =>
  httpClient.get(`${url}/genre-stream-chart?genre=${genre}`);

const getRadarChartValuesOfArtist = (spotifyId, genre) =>
  httpClient.get(
    `/mongo-chartmetric-genres/artist-radial?spotifyID=${spotifyId}&genre=${genre}`
  );

const getMongoAggregatedGenre = () => httpClient.get(`mongo-genre`);

const getMongoAggregatedArtistByGenre = (genre) =>
  httpClient.get(`${MONGO_GENRE}/specific-genre?genre=${genre}`);

const getArtistByGenreFromExternalSearch = (genre, offset, limit) =>
  httpClient.get(
    `${MONGO_GENRE}/specific-genre-from-external-search?genre=${genre}&offset=${offset}&limit=${limit}`
  );

const getGenreHierarchy = () =>
  httpClient.get(`${MONGO_GENRE}/all-genres-hierarchy`);

const getChartmetricGenres = () =>
  httpClient.get(`${MONGO_CHARTMETRIC_GENRE}/get-chartmetric-genres`);

const getLiveGenresFromChartmetric = () =>
  httpClient.get(`${MONGO_CHARTMETRIC_GENRE}/get-live-genres`);

export {
  getAllGenresFromMongo,
  getAllGenres,
  getGenreResult,
  getGenreDetailFromMongoV2,
  getGenreListenersGrowthV2,
  getGenreListenerBenchmarksV2,
  getGenreStreamsV2,
  getRadarChartValuesOfArtist,
  getMongoAggregatedGenre,
  getMongoAggregatedArtistByGenre,
  getArtistByGenreFromExternalSearch,
  getGenreHierarchy,
  getChartmetricGenres,
  getLiveGenresFromChartmetric,
};
