<template>
  <div class="wishlists h-100 mb-10 mb-xl-10" v-loading="loading">
    <div class="wishlist-head-title ms-12 mb-5">Wishlists</div>
    <div class="row g-6 g-xl-9" v-if="wishlists.length">
      <div class="col-md-6 col-xl-4" v-for="list in wishlists" :key="list._id">
        <WishlistCard :wishlist="list" icon="media/svg/brand-logos/plurk.svg" />
      </div>
    </div>
    <div
      v-if="!wishlists.length && !loading"
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <h3 class="heading">You currently have no wishlist.</h3>
      <a
        class="btn btn-sm btn-primary me-3"
        data-bs-toggle="modal"
        data-bs-target="#create_tracks_wishlist_modal"
        >Create Wishlist</a
      >
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import WishlistCard from "@/wishlist/components/WishlistCard.vue";

export default {
  name: "Wishlists",
  components: { WishlistCard },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const wishlists = computed(() => {
      return store.getters["WishlistModule/getWishlists"];
    });
    onMounted(async () => {
      if (!wishlists.value.length) {
        try {
          loading.value = true;
          await store.dispatch("WishlistModule/fetchWishlists");
        } catch (e) {
          console.log(e);
        } finally {
          loading.value = false;
        }
      }
    });
    return { loading, wishlists };
  },
};
</script>

<style lang="scss" scoped>
.wishlist-head-title {
  font-weight: 700;
  color: #33647f;
  font-size: 20px;
}
</style>
