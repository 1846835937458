import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getAuth } from "firebase/auth";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import store from "@/store/store";
import { Category } from "@/common/enums/categoryEnum";

const userInfo = store.getters["UserModule/getUserInfo"];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard-old",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/dashboard",
        name: "dashboardV2",
        component: () => import("@/views/DashboardV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/artists",
        name: "artists",
        component: () => import("@/artists/views/Artists.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/ai-artist/:spotifyID",
        name: "ArtistDetailV2",
        component: () => import("@/artists/views/ArtistsDetailV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist Detail V2",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/ai-forecast/:artistName/:spotifyID",
        name: "ForecastDetailV2",
        component: () => import("@/forecast/ForecastDetailV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Forecast Detail V2",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/ai-artist-extern/:spotifyID/:uuid",
        name: "ExternArtistDetailV2",
        component: () => import("@/artists/views/ArtistsDetailV2.vue"),
        meta: {
          // requiresAuth: true,
          title: "Extern Forecast Detail V2",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/ai-forecast-extern/:artistName/:spotifyID",
        name: "ExternalForecastDetailV2",
        component: () => import("@/forecast/ForecastDetailV2.vue"),
        meta: {
          // requiresAuth: true,
          title: "Extern Artist Detail V2",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/genre-v2",
        name: "GenreDetailV2",
        component: () => import("@/genre/GenreDetailV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Genre Detail V2",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/genres",
        name: "genreMappingV2",
        component: () => import("@/genre/genreMappingV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Genre Mapping",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/charts",
        name: "chartsOverviewV2",
        component: () => import("@/artists/views/ChartsOverviewV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Charts Overview",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/search-new-artists",
        name: "SearchNewArtistsV2",
        component: () => import("@/artists/views/SearchNewArtistsV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Search New Artists",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/search-tracks-by-labels",
        name: "SearchTracksByLabels",
        component: () => import("@/tracks/views/SearchTracksByLabels.vue"),
        meta: {
          requiresAuth: true,
          title: "Search Tracks By Labels",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/artist-valuation/:spotifyID",
        name: "ArtistValuationV2",
        component: () => import("@/artists/views/ValuationV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist Valuation",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/ai-catalogues",
        name: "ExternalAiArtists",
        component: () => import("@/artists/views/ExternalAiArtists.vue"),
        meta: {
          requiresAuth: true,
          title: "External Ai Artists",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "artistsV2",
        name: "ArtistsSearchResult",
        component: () => import("@/artists/components/ArtistsSearchV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Artists Search Result",
          version: "v2",
          headerFixed: true,
        },
      },
      {
        path: "/song/:spotifyID/:trackID",
        name: "SongDetailV2",
        component: () => import("@/song/SongDetailV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Song Detail",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/score-analytics/:spotifyID/",
        name: "ScoresDetailV2",
        component: () => import("@/scores/ScoresDetailV2.vue"),
        meta: {
          requiresAuth: true,
          title: "Scores Detail",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/wishlists",
        name: "wishlist",
        component: () => import("@/wishlist/views/Wishlists.vue"),
        meta: {
          requiresAuth: true,
          title: "Wishlists",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/wishlist/:wishlistID",
        name: "wishlistDetail",
        component: () => import("@/wishlist/views/WishistDetail.vue"),
        meta: {
          requiresAuth: true,
          title: "Wishlist Detail",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/artists/:uuid",
        name: "artistDetail",
        component: () => import("@/artists/views/ArtistDetail.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist Detail",
          version: "v1",
          headerFixed: true,
        },
        children: [
          {
            path: "overview",
            name: "artistOverview",
            component: () => import("@/artists/views/Overview.vue"),
            meta: {
              requiresAuth: true,
              title: "Overview",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "fanbase",
            name: "fanbase",
            component: () => import("@/artists/views/Fanbase.vue"),
            meta: {
              requiresAuth: true,
              title: "Fanbase",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "listeners",
            name: "artistListeners",
            component: () => import("@/artists/views/Listeners.vue"),
            meta: {
              requiresAuth: true,
              title: "Listeners",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "spotify",
            name: "spotify",
            component: () => import("@/artists/views/Spotify.vue"),
            meta: {
              requiresAuth: true,
              title: "Spotify",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "airplay",
            name: "airplay",
            component: () => import("@/artists/views/Airplay.vue"),
            meta: {
              requiresAuth: true,
              title: "Airplay",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "playlist",
            name: "playlist",
            component: () => import("@/artists/views/Playlist.vue"),
            meta: {
              requiresAuth: true,
              title: "Playlist",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "forecast",
            name: "forecast",
            component: () => import("@/artists/views/ArtistForecast.vue"),
            meta: {
              requiresAuth: true,
              title: "Watchlist Forecast",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "analytics",
            name: "analytics",
            component: () => import("@/artists/views/Analytics.vue"),
            meta: {
              requiresAuth: true,
              title: "Analytics",
              version: "v1",
              headerFixed: true,
            },
          },
        ],
      },
      {
        path: "/funds",
        name: "funds",
        component: () => import("@/funds/views/Funds.vue"),
        meta: {
          requiresAuth: true,
          title: "Funds",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/fund/:fundID",
        name: "fundDetail",
        component: () => import("@/funds/views/FundDetail.vue"),
        meta: {
          requiresAuth: true,
          title: "Fund Detail",
          version: "v2",
          headerFixed: false,
        },
      },
      {
        path: "/ai-beta-bench",
        name: "aiBetaBench",
        redirect: "/ai-beta-bench/search-results",
        component: () => import("@/aiBetaBench/views/Index.vue"),
        meta: {
          requiresAuth: true,
          title: "AI Beta Bench",
          version: "v1",
          headerFixed: true,
        },
        children: [
          {
            path: "search-results",
            name: "Search",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Search",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "listeners",
            name: "aiBetaBenchListeners",
            component: () =>
              import("@/aiBetaBench/views/SearchResultListeners.vue"),
            meta: {
              requiresAuth: true,
              title: "Listeners",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "playlist",
            name: "aiBetaBenchPlaylists",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Playlists",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "/followers",
            name: "aiBetaBenchFollowers",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Followers",
              version: "v1",
              headerFixed: true,
            },
          },
          {
            path: "/fanbase",
            name: "aiBetaBenchFanbase",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Fanbase",
              version: "v1",
              headerFixed: true,
            },
          },
        ],
      },
      {
        path: "/crawler",
        name: "crawler",
        component: () => import("../crawler/views/Crawler.vue"),
        meta: {
          requiresAuth: true,
          title: "Crawler",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/aiGenre",
        name: "aiGenre",
        component: () => import("../aiGenre/views/AiGenre.vue"),
        meta: {
          requiresAuth: true,
          title: "aiGenre",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/crawler-history",
        name: "crawlerHistory",
        // beforeEnter: guardRoute,
        component: () => import("../crawler/views/CrawlerSavedHistory.vue"),
        meta: {
          requiresAuth: true,
          title: "Crawler",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/aiArtists",
        name: "PreFilledGenres",
        component: () => import("../prefilledGenres/views/PrefilledGenres.vue"),
        meta: {
          requiresAuth: true,
          title: "AI Artists",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/ai-artists-2",
        name: "PreFilledGenresNew",
        component: () =>
          import("../prefilledGenres/views/PrefilledGenresNew.vue"),
        meta: {
          requiresAuth: true,
          title: "AI Artists 2",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/watchlist",
        name: "watchlists",
        component: () => import("@/user/views/Watchlists.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlists",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/watchlist/:id",
        name: "watchlistDetails",
        component: () => import("@/user/views/WatchlistDetails.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlists",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/watchlist/:id/forecast",
        name: "watchlistForecast",
        component: () => import("@/user/views/WatchlistForecast.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlist Forecast",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/discover",
        name: "discover",
        component: () => import("@/discover/views/Discover.vue"),
        meta: {
          privatePublic: true,
          requiresAuth: false,
          title: "Discover Quickly",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/callback-spotify",
        name: "callback",
        component: () => import("@/discover/components/CallbackSpotify.vue"),
        meta: {
          privatePublic: true,
          requiresAuth: false,
          title: "Callback",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/profile/views/UserProfile.vue"),
        meta: {
          requiresAuth: true,
          title: "Profile",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/account-management",
        name: "AccountManagement",
        component: () => import("@/admin/AccountManagement.vue"),
        meta: {
          requiresAuth: true,
          title: "Account Management ",
          version: "v1",
          headerFixed: true,
        },
      },
      {
        path: "/schedulers",
        name: "Schedulers",
        component: () => import("@/admin/Schedulers.vue"),
        meta: {
          requiresAuth: true,
          title: "Cron Jobs",
          version: "v1",
          headerFixed: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("../auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("../auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("../auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/ai-artist-public/:spotifyID",
    name: "ArtistLimitedDetailV2",
    component: () => import("@/artists/views/ArtistsDetailLimitedV2.vue"),
    meta: {
      title: "Artist public Detail V2",
      version: "v2",
      headerFixed: false,
    },
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/account-status",
    name: "accountStatus",
    component: () => import("@/views/StatusV2.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const clearSearchQueryIfRequired = (to) => {
  if (
    to.name !== "artists" &&
    to.name !== "discover" &&
    to.name !== "callback"
  ) {
    store.dispatch("EventsModule/clearSearchQuery");
  }
};

const adminRoutesNames = ["AccountManagement", "Schedulers"];
const routesProtectedFrom = [
  Category.USER,
  Category.BLINGROCK,
  Category.ARTIST,
];

const authenticateRoutes = (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.meta.requiresAuth;
  if (
    !requiresAuth &&
    currentUser &&
    !to.meta.privatePublic &&
    userInfo &&
    userInfo.isApproved &&
    !to.meta.public
  ) {
    next("/dashboard");
  } else if (!currentUser && requiresAuth) {
    next("/sign-in");
  } else if (requiresAuth && !currentUser && userInfo && !userInfo.isApproved) {
    next("/account-status");
  } else if (
    currentUser &&
    userInfo &&
    userInfo.isApproved &&
    routesProtectedFrom.includes(userInfo.category) &&
    adminRoutesNames.includes(to.name)
  ) {
    next("/dashboard");
  }
};
const setPageTitle = (to) => {
  if (to.meta.requiresAuth) {
    setCurrentPageTitle(String(to.meta.title));
  }
};

router.beforeEach((to, from, next) => {
  setPageTitle(to);
  clearSearchQueryIfRequired(to);
  authenticateRoutes(to, from, next);
  next();
});

export default router;
