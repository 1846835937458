import httpClient from "./httpClient";

const FUND = "/fund";

const getAllFunds = () => httpClient.get(`${FUND}`);

const getFundDetail = (fundID) => httpClient.get(`${FUND}/${fundID}`);

const createFund = (payload) => httpClient.post(`${FUND}`, payload);

const updateFund = (payload) => httpClient.put(`${FUND}`, payload);

const deleteFund = (fundID) => httpClient.delete(`${FUND}/${fundID}`);

export { createFund, getAllFunds, getFundDetail, updateFund, deleteFund };
