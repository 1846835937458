import httpClient from "./httpClient";

const USER = "/user";
const USER_SEARCH_HISTORY = `${USER}/user-search-history`;
const USER_WATCHLIST = `${USER}/watch-list`;
// const DISCOGS = "/discogs";
const Save = "/save";

const createUser = (category, email) =>
  httpClient.post(`${USER}/create-user`, {
    category: category,
    email: email,
  });

const getUser = () => httpClient.get(`${USER}`);

const getAllUsers = (search_query) =>
  httpClient.get(`${USER}/all-users?search_query=${search_query}`);

const updateUserAccount = (user) =>
  httpClient.put(`${USER}/update-user-account`, {
    uuid: user.uuid,
    category: user.category,
    isApproved: user.isApproved,
    email: user.email,
  });

const getFollowedArtists = () => httpClient.get(`${USER}/followed`);

const followArtist = (uuid) =>
  httpClient.post(`${USER}/followed/follow`, {
    uuid: uuid,
  });

const saveArtistApi = (uuid) => httpClient.post(`save/${uuid}`);

const saveMongoArtistDetail = (spotifyID, force) =>
  httpClient.post(`/mongo-artist-detail?spotifyID=${spotifyID}&force=${force}`);

const unfollowArtist = (uuid) =>
  httpClient.post(`${USER}/followed/un-follow`, {
    uuid: uuid,
  });

const getUserSearchHistory = () => httpClient.get(`${USER_SEARCH_HISTORY}`);

const updateUserSearchHistory = (uuid) =>
  httpClient.post(`${USER_SEARCH_HISTORY}`, {
    uuid,
  });

const updateWatchlistPosition = (watchlistPosition) =>
  httpClient.post(`${USER_WATCHLIST}/update-position`, watchlistPosition);

const getWatchlist = () => httpClient.get(`${USER_WATCHLIST}`);

const addNewWatchlist = (watchlist) =>
  httpClient.post(`${USER_WATCHLIST}`, watchlist);

const addArtistToWatchlist = (artistID, watchlistID) =>
  httpClient.post(`${USER_WATCHLIST}/artist/add-artist`, {
    artist: {
      uuid: artistID,
    },
    userWatchList: {
      id: watchlistID,
    },
  });

const deleteArtistFromWatchlist = (artistID, watchlistID) =>
  httpClient.delete(`${USER_WATCHLIST}/artist/remove-artist`, {
    data: {
      artist: {
        uuid: artistID,
      },
      userWatchList: {
        id: watchlistID,
      },
    },
  });

const deleteUserWatchlist = (watchlistID) =>
  httpClient.delete(`${USER_WATCHLIST}/${watchlistID}`);

const addDiscogsSearchHistory = (discogId) =>
  httpClient.post(`save/${discogId}`);

const associateDiscogsSearchHistory = (payload) =>
  httpClient.post(`save/associate`, payload);

const getAllDiscogsSearchHistory = (offset, limit) =>
  httpClient.get(`${Save}?offset=${offset}&limit=${limit}`);

const deleteDiscogsSearchHistoryById = (id) =>
  httpClient.delete(`${Save}/${id}`);

const deleteBatchDiscogsSearchHistoryId = (ids) =>
  httpClient.post(`${Save}/batch/delete`, { ids });

const increaseUserSearchCountV2 = (spotifyID) =>
  httpClient.post(`${USER}/user-search-history-mongo`, {
    spotifyID,
  });

const followUnFollowArtistV2 = (spotifyID) =>
  httpClient.post(`${USER}/follow-unfollow-artist-mongo`, {
    spotifyID,
  });

export {
  createUser,
  getUser,
  getAllUsers,
  updateUserAccount,
  getFollowedArtists,
  followArtist,
  unfollowArtist,
  getUserSearchHistory,
  updateUserSearchHistory,
  getWatchlist,
  addNewWatchlist,
  addArtistToWatchlist,
  deleteUserWatchlist,
  deleteArtistFromWatchlist,
  updateWatchlistPosition,
  addDiscogsSearchHistory,
  getAllDiscogsSearchHistory,
  deleteDiscogsSearchHistoryById,
  deleteBatchDiscogsSearchHistoryId,
  saveArtistApi,
  associateDiscogsSearchHistory,
  saveMongoArtistDetail,
  increaseUserSearchCountV2,
  followUnFollowArtistV2,
};
