
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.dispatch("UserModule/checkUserData");
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      // store.dispatch("AuthModule/authAction");
      window.addEventListener("storage", function (event) {
        if (event.key == "set-spotify-token") {
          const data = localStorage.getItem("set-spotify-token");
          if (data) {
            store.dispatch(
              "SpotifyAccessTokenHelperModule/setUserAndToken",
              JSON.parse(data)
            );
          }
        }
        if (event.key == "refresh-spotify-token") {
          const data = localStorage.getItem("refresh-spotify-token");
          if (data) {
            const payload = JSON.parse(data);
            store.commit(
              "SpotifyAccessTokenHelperModule/SET_ACCESS_TOKEN",
              payload.accessToken
            );
            store.commit(
              "SpotifyAccessTokenHelperModule/SET_EXPIRY_TIME",
              payload.expiresIn
            );
          }
        }
        if (event.key === "fetch-discogs-search-history") {
          store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
        }
      });
    });

    onUnmounted(() => {
      window.removeEventListener("storage", () => {
        //
      });
    });
  },
});
