import httpClient from "./httpClient";

const MONGO_ARTIST_DETAIL = "/mongo-artist-detail";
const MONGO_PLAYLIST_METRIC = "/mongo-playlist-metric";
const MONGO_FORECAST = "/mongo-forecast";
const MONGO_PLATFORM_FOLLOWER = "/mongo-platform-follower";
const ARTIST_VALUE = "/artist-value";
const SEARCH_PAGE = "/search-page";

const getArtistByName = (searchQuery) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/artist/${searchQuery}`);

const fetchArtistDataV2FromMongo = (spotifyId) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/${spotifyId}`);

const getArtistOverviewDetailV2FromMongo = (spotifyId) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/artist-overview/${spotifyId}`);

const getArtistDownloadDataV2FromMongo = (uuid) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/artist-download-data/${uuid}`);

const getArtistsBySpotifySearch = (searchQuery) =>
  httpClient.get(
    `${MONGO_ARTIST_DETAIL}/artists-by-spotify-search/${searchQuery}`
  );
const getArtistUUIDBySpotifyID = (spotifyId) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/uuid-by-spotifyID/${spotifyId}`);

const getBlingRockScore = (spotifyID) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/br-score/${spotifyID}`);

const userSearchedHistoryV2 = (limit) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/user-searched-history?limit=${limit}`);

const userFollowedArtistsV2 = (limit) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/user-followed-artist?limit=${limit}`);

const getArtistGrowthBySpotifyID = (spotifyID) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/artist-growths/${spotifyID}`);

const getArtistRatioChartValuesFromMongoV2 = (spotifyID) =>
  httpClient.get(`${MONGO_ARTIST_DETAIL}/artist-ratios/${spotifyID}`);

const fetchArtistsPlaylistsDataV2FromMongo = (spotifyID) =>
  httpClient.get(`${MONGO_PLAYLIST_METRIC}/${spotifyID}`);

const fetchArtistsPlaylistsDataWithSpotifyListenersV2FromMongo = (spotifyID) =>
  httpClient.get(`${MONGO_PLAYLIST_METRIC}/listeners-playlist/${spotifyID}`);

const getArtistPlaylistBySpotifyID = (spotifyID, status) =>
  httpClient.get(
    `${MONGO_PLAYLIST_METRIC}/artist-playlists/${spotifyID}/${status}`
  );

const getArtistSpotifyListenersForecastsV2FromMongo = (spotifyID) =>
  httpClient.get(`${MONGO_FORECAST}/listeners-forecast/${spotifyID}`);

const getArtistSpotifyFollowersForecastsV2FromMongo = (spotifyID) =>
  httpClient.get(`${MONGO_FORECAST}/followers-forecast/${spotifyID}`);

const getArtistHistoricForecastV2FromMongo = (spotifyID) =>
  httpClient.get(`${MONGO_FORECAST}/historic-forecast/${spotifyID}`);

const getArtistSongsStreamsV2FromMongo = (uuid) =>
  httpClient.get(`/mongo-song-stream/${uuid}`);

const getFanmetricSourcesBySpotifyID = (
  spotifyID,
  field,
  source,
  years,
  order,
  allListeners
) =>
  httpClient.get(
    `mongo-fanmetric/${spotifyID}/${field}/${source}/${years}/${order}/${
      allListeners ? allListeners : false
    }`
  );

const getArtistFanbaseFollowersDistributionV2FromMongo = (spotifyID) =>
  httpClient.get(
    `${MONGO_PLATFORM_FOLLOWER}/fanbase-distribution?spotifyID=${spotifyID}`
  );

const getArtistValue = (spotifyID, force) =>
  httpClient.get(`${ARTIST_VALUE}/${spotifyID}/${force}`);

const saveDataIntoToBeScrappedDocument = (payload) =>
  httpClient.post(`${MONGO_ARTIST_DETAIL}/save-data-to-be-scraped`, payload);

const getChartsArtistFromMongo = (payload) =>
  httpClient.post(`/mongo-charts`, payload);

const searchArtistByFilters = (payload) =>
  httpClient.post(`${SEARCH_PAGE}/artist-search`, payload);

const saveSearchArtistsByChartmetricIDS = (payload) =>
  httpClient.post(`${SEARCH_PAGE}/save-artist-search`, payload);

const updateArtistListenersTrend = (spotifyID, payload) =>
  httpClient.put(`${MONGO_ARTIST_DETAIL}/trend-growth/${spotifyID}`, payload);

const deleteArtistFromDB = (spotifyID) =>
  httpClient.delete(`${MONGO_ARTIST_DETAIL}?spotifyID=${spotifyID}`);

export {
  getArtistByName,
  fetchArtistDataV2FromMongo,
  getArtistOverviewDetailV2FromMongo,
  getArtistDownloadDataV2FromMongo,
  getArtistsBySpotifySearch,
  getArtistUUIDBySpotifyID,
  getBlingRockScore,
  userSearchedHistoryV2,
  userFollowedArtistsV2,
  getArtistGrowthBySpotifyID,
  getArtistRatioChartValuesFromMongoV2,
  fetchArtistsPlaylistsDataV2FromMongo,
  fetchArtistsPlaylistsDataWithSpotifyListenersV2FromMongo,
  getArtistSpotifyListenersForecastsV2FromMongo,
  getArtistSpotifyFollowersForecastsV2FromMongo,
  getArtistHistoricForecastV2FromMongo,
  getFanmetricSourcesBySpotifyID,
  getArtistSongsStreamsV2FromMongo,
  getArtistFanbaseFollowersDistributionV2FromMongo,
  getArtistPlaylistBySpotifyID,
  saveDataIntoToBeScrappedDocument,
  getChartsArtistFromMongo,
  getArtistValue,
  searchArtistByFilters,
  saveSearchArtistsByChartmetricIDS,
  updateArtistListenersTrend,
  deleteArtistFromDB,
};
