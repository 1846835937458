import { ElementAnimateUtil } from "@/assets/ts/_utils";
import { getAllGenresFromMongo } from "@/api/genreResult";

const state = {
  currentlySelectedMenu: "New Releases",
  genres: [],
};

const getters = {
  getCurrentlySelectedMenu(state) {
    return state.currentlySelectedMenu;
  },
  getAllGenres(state) {
    return state.genres;
  },
};

const actions = {
  async changeSelectedMenu({ commit }, selectedMenu) {
    commit("CHANGE_SELECTED_MENU", selectedMenu);
  },

  async resetStore({ commit }) {
    commit("RESET_STORE");
  },

  async fetchGenres({ state, commit }, forceFetch) {
    if (forceFetch || state.genres.length === 0) {
      try {
        // const { data } = await getAllGenres();
        const { data } = await getAllGenresFromMongo();
        const genres = data.map((object) => {
          return {
            value: object.name,
            label: object.name,
            total_artists: object.totalArtists,
            status: object.status,
          };
        });
        commit("SET_GENRES", genres);
      } catch (e) {
        //
      }
    }
  },

  // eslint-disable-next-line no-empty-pattern
  scrollToElement({}, id) {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        ElementAnimateUtil.scrollTo(element, 50, 800);
      }, 500);
    }
  },
};

const mutations = {
  CHANGE_SELECTED_MENU(state, selectedMenu) {
    state.currentlySelectedMenu = selectedMenu;
  },
  RESET_STORE(state) {
    state.currentlySelectedMenu = "Genres";
  },
  SET_GENRES(state, payload) {
    state.genres = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
