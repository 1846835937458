import $ from "jquery";
import router from "@/router";

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const scrollToElementWithMargin = (elementId, marginTop) => {
  const position = document.getElementById(elementId);
  window.scrollTo({
    top: position.offsetTop - marginTop,
    behavior: "smooth",
  });
};

const routeToPage = async (route, blank = false) => {
  if (blank) {
    window.open(`/${route}`, "_blank");
  } else {
    await router.push(`/${route}`);
  }
};

const getSocialSitesFromChartMetricArray = (array) => {
  const obj = {};
  if (array.length > 0) {
    for (const arrayElement of array) {
      if (arrayElement.domain === "facebook") {
        Object.assign(obj, { facebook: arrayElement.url });
      }
      if (arrayElement.domain === "instagram") {
        Object.assign(obj, { instagram: arrayElement.url });
      }
      if (arrayElement.domain === "youtube") {
        Object.assign(obj, { youtube: arrayElement.url });
      }
      if (arrayElement.domain === "twitter") {
        Object.assign(obj, { twitter: arrayElement.url });
      }
      if (arrayElement.domain === "soundcloud") {
        Object.assign(obj, { soundcloud: arrayElement.url });
      }
    }
  }
  return obj;
};
const getSocialSitesFromGoogleScrapedContacts = (object) => {
  const obj = {};
  if (object && object.facebook) {
    Object.assign(obj, { facebook: object.facebook });
  }
  if (object && object.instagram) {
    Object.assign(obj, { instagram: object.instagram });
  }
  if (object && object.youtube) {
    Object.assign(obj, { youtube: object.youtube });
  }
  if (object && object.twitter) {
    Object.assign(obj, { twitter: object.twitter });
  }
  if (object && object.soundcloud) {
    Object.assign(obj, { soundcloud: object.soundcloud });
  }
  return obj;
};
const formatNumberIntoHumanizeForm = (value, fraction = 1) => {
  if (value) {
    // if (value < 0) {
    //   value = value * -1;
    //   return "-" + humanize.intword(value);
    // } else {
    //   return humanize.intword(value);
    // }
    if (typeof value === "number") {
      value = Number(value.toFixed(fraction));
    }
    return value.toLocaleString("de-DE");
  } else {
    // return humanize.intword(0);
    return 0;
  }
};

const checkIsValueAvailableOrNot = (
  type,
  obj,
  objKey,
  unit = "",
  fraction = 1
) => {
  if (type === "object" && obj) {
    return obj[objKey] || obj[objKey] === 0
      ? formatNumberIntoHumanizeForm(obj[objKey], fraction) + unit
      : "N/A";
  } else if (type === "key") {
    return objKey || objKey === 0
      ? formatNumberIntoHumanizeForm(objKey, fraction) + unit
      : "N/A";
  }
};

const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

const capitalizeEveryWordFirstLetter = (value) => {
  if (value) {
    return value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  } else {
    return "";
  }
};

const chunkArrayIntoEqualSizes = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

const sumArrayElements = (array) => {
  return array.reduce(function (x, y) {
    return Number(x) + Number(y);
  }, 0);
};

const checkIfValueGreaterThanZeroThenHumanize = (val, fraction = 1) => {
  if (val > 0) {
    return formatNumberIntoHumanizeForm(val, fraction);
  } else {
    return 0;
  }
};

const getPlatformColor = (platform) => {
  const colors = {
    spotify: "#48B955",
    instagram: "#C14B84",
    soundcloud: "#FB663B",
    twitter: "#01ACEE",
    tiktok: "#000000",
    youtube: "#FA423B",
    deezer: "#e6e600",
    line: "#3AB54A",
    melon: "#41B64A",
    facebook: "#4767AA",
    shazam: "#2979FF",
  };
  return colors[platform];
};

const tableRowClassName = ({ rowIndex }) => {
  return rowIndex % 2 === 0
    ? "table-data-text"
    : "table-data-text striped-table-row";
};

const tableCellClassName = ({ columnIndex }) => {
  if (columnIndex === 0 || columnIndex === 1) {
    return "table-column-ellipses";
  } else {
    return "";
  }
};

const calculateChartHeight = (array) => {
  return array.length * 30;
};

const getUniqueValuesFromArray = (array, uniqueKey) => {
  return Array.from(
    new Set(
      array.map((item) => item[uniqueKey].replace(/\s/g, "").toLowerCase())
    )
  ).map((key) => {
    return array.find(
      (item2) => item2[uniqueKey].replace(/\s/g, "").toLowerCase() === key
    );
  });
};

$(document).on("mouseenter", ".truncate", function () {
  const tooltip = $(this).attr("data-tooltip");
  if (tooltip) {
    const tagName = $(this).attr("data-tag");
    const text = $(this).attr("data-text");
    if (this.offsetWidth < this.scrollWidth) {
      $(tagName).attr({
        title: text,
      });
    } else {
      $(tagName).attr({ title: "" });
    }
  }
});
export {
  sleep,
  scrollToElementWithMargin,
  getSocialSitesFromChartMetricArray,
  getSocialSitesFromGoogleScrapedContacts,
  formatNumberIntoHumanizeForm,
  checkIsValueAvailableOrNot,
  capitalizeFirstLetter,
  capitalizeEveryWordFirstLetter,
  sumArrayElements,
  chunkArrayIntoEqualSizes,
  checkIfValueGreaterThanZeroThenHumanize,
  getPlatformColor,
  routeToPage,
  tableRowClassName,
  tableCellClassName,
  calculateChartHeight,
  getUniqueValuesFromArray,
};
