import { createApp } from "vue";
import App from "./App.vue";
import { onAuthStateChanged, getAuth } from "firebase/auth";

import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import loadStock from "highcharts/modules/stock";
import loadIndicatorsAll from "highcharts/indicators/indicators-all";
loadStock(Highcharts);
loadIndicatorsAll(Highcharts);
highchartsMore(Highcharts);
import JsonExcel from "vue-json-excel3";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store/store.js";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import firebase from "@/common/plugins/firebase";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import { artist } from "../src/mixins/artist/artist";
import emitter from "tiny-emitter/instance";

let app;

onAuthStateChanged(getAuth(), () => {
  if (!app) {
    app = createApp(App);
    app.config.globalProperties.$emitter = emitter;
    app.use(store);
    app.mixin(artist);

    app.use(router);
    app.use(ElementPlus);
    app.use(firebase);
    ApiService.init(app);
    initApexCharts(app);
    initInlineSvg(app);
    initVeeValidate();
    app.component("downloadExcel", JsonExcel);
    app.use(i18n);
    app.mount("#app");
  }
});
