import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import ArtistModule from "../store/modules/Artist.store.js";
import UserModule from "../store/modules/User.store.js";
import BodyModule from "../store/modules/BodyModule";
import BreadcrumbsModule from "../store/modules/BreadcrumbsModule";
import ConfigModule from "../store/modules/ConfigModule";
import EventsModule from "../store/modules/Events.store.js";
import DiscoverModule from "../store/modules/Discover.store.js";
import SpotifyAccessTokenHelperModule from "../store/modules/SpotifyAccessTokenHelper.store.js";
import TrackPlaybackModule from "../store/modules/TrackPlayback.store.js";
import ForecastModule from "@/store/modules/Forecast.store";
import createPersistedState from "vuex-persistedstate";
import AIBetaBench from "@/store/modules/AIBetaBench";
import WishlistModule from "@/store/modules/Wishlist.store";
import FundsModule from "@/store/modules/Funds.store";

config.rawError = true;
const store = createStore({
  modules: {
    AIBetaBench,
    ArtistModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    DiscoverModule,
    EventsModule,
    SpotifyAccessTokenHelperModule,
    TrackPlaybackModule,
    UserModule,
    ForecastModule,
    WishlistModule,
    FundsModule,
  },
  plugins: [
    createPersistedState({
      paths: [
        "ArtistModule",
        "UserModule",
        "DiscoverModule",
        "SpotifyAccessTokenHelperModule",
        "EventsModule",
      ],
      storage: window.sessionStorage,
      reducer(state) {
        return {
          ArtistModule: state.ArtistModule,
          UserModule: state.UserModule,
          DiscoverModule: state.DiscoverModule,
          SpotifyAccessTokenHelperModule: state.SpotifyAccessTokenHelperModule,
          EventsModule: state.EventsModule,
        };
      },
    }),
  ],
});

export default store;
