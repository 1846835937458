import { getAllFunds, getFundDetail } from "@/api/mongoFund";

const state = {
  funds: [],
  fundDetail: {},
};

const getters = {
  getFunds(state) {
    return state.funds;
  },
  getFundDetail(state) {
    return state.fundDetail;
  },
};
const actions = {
  async fetchFunds({ commit }) {
    try {
      const { data } = await getAllFunds();
      commit("SET_FUNDS", data && data.length > 0 ? data : []);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchFundDetail({ commit }, fundID) {
    try {
      const { data } = await getFundDetail(fundID);
      commit("SET_FUND_DETAIL", data);
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  SET_FUNDS(state, payload) {
    state.funds = payload;
  },
  SET_FUND_DETAIL(state, payload) {
    state.fundDetail = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
