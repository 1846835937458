import SpotifyWebApi from "spotify-web-api-node";

export default async function useSpotifyApi(store) {
  const accessToken = await store.dispatch(
    "SpotifyAccessTokenHelperModule/getAccessToken"
  );
  const spotifyApi = new SpotifyWebApi();
  spotifyApi.setAccessToken(accessToken);
  return spotifyApi;
}
