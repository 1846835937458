<template>
  <div
    class="
      card
      border border-2 border-gray-300 border-hover
      h-100
      cursor-pointer
    "
    v-loading.fullscreen.lock="loading"
  >
    <div
      class="d-flex justify-content-end w-100"
      @click="deleteWishlist(wishlist._id)"
    >
      <span
        class="
          svg-icon svg-icon-1
          p-2
          btn btn-md btn-icon btn-active-color-primary
        "
      >
        <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
      </span>
    </div>
    <div @click="routeToPage(`wishlist/${wishlist._id}`)">
      <div class="card-header border-top border-bottom-0">
        <div class="card-title mt-4">
          <div class="symbol symbol-50px w-80px h-80px">
            <img
              class="w-100 h-100"
              :src="
                wishlist.wishlistTracks && wishlist.wishlistTracks.length
                  ? wishlist.wishlistTracks[0].imageURL
                  : '/media/avatars/blank.png'
              "
              alt="image"
            />
          </div>
        </div>
        <div class="card-toolbar">
          <span
            :class="[
              'badge fw-bolder me-auto px-4 py-3',
              wishlist.isPublic ? 'badge-light-primary' : 'badge-light-danger',
            ]"
            >{{ wishlist.isPublic ? "Public" : "Private" }}</span
          >
          <span
            v-if="wishlist.fundSlot"
            :class="[
              'badge fw-bolder me-auto px-4 py-3 ms-1',
              wishlist.fundSlot === WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
                ? 'badge-light-primary'
                : 'badge-light-danger',
            ]"
            >{{
              wishlist.fundSlot === WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
                ? "Acquired Catalogue"
                : "Equity Catalogue"
            }}</span
          >
        </div>
      </div>
      <div class="card-body p-9">
        <div class="fs-3 fw-bolder text-dark">
          {{ capitalizeEveryWordFirstLetter(wishlist.name) }}
        </div>
        <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7"></p>
        <div class="d-flex flex-wrap mb-5">
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-7
              mb-3
            "
          >
            <div class="fs-6 text-gray-800 fw-bolder">
              {{
                getTotalSpotifyStreams(
                  wishlist.wishlistTracks.length ? wishlist.wishlistTracks : []
                )
              }}
            </div>
            <div class="fw-bold text-gray-400">Total Spotify Streams</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              mb-3
            "
          >
            <div class="fs-6 text-gray-800 fw-bolder">
              {{ wishlist.wishlistTracks.length }}
            </div>
            <div class="fw-bold text-gray-400">Total Tracks</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              mb-3
            "
          >
            <div class="fs-6 text-gray-800 fw-bolder">
              {{ moment(wishlist.createdAt).format("MMM DD, YYYY") }}
            </div>
            <div class="fw-bold text-gray-400">Created At</div>
          </div>
        </div>

        <template v-if="wishlist.wishlistTracks.length">
          <div class="symbol-group symbol-hover">
            <template
              v-for="(track, index) in wishlist.wishlistTracks.slice(0, 6)"
              :key="index"
            >
              <div
                class="symbol symbol-35px symbol-circle"
                data-bs-toggle="tooltip"
                :title="track.track"
              >
                <img v-if="track.imageURL" alt="Pic" :src="track.imageURL" />
                <span
                  v-else
                  class="symbol-label fw-bolder"
                  :class="`bg-primary text-inverse-primary`"
                  >{{ track.track ? track.track.charAt(0) : "" }}</span
                >
              </div>
            </template>
            <div
              class="symbol symbol-35px symbol-circle"
              v-if="wishlist.wishlistTracks.length > 6"
            >
              <a
                href="javascript:void(0);"
                class="symbol symbol-35px symbol-circle"
              >
                <span class="symbol-label bg-light text-gray-400 fs-8 fw-bolder"
                  >+{{ wishlist.wishlistTracks.length - 5 }}</span
                >
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import {
  capitalizeEveryWordFirstLetter,
  formatNumberIntoHumanizeForm,
  routeToPage,
} from "@/utils/staticHelper";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { deleteUserWishlist } from "@/api/mongoWishlists.api";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";
import moment from "moment";

export default {
  name: "WishlistCard",
  computed: {
    moment() {
      return moment;
    },
    WishlistFundEnum() {
      return WishlistFundEnum;
    },
  },
  methods: {
    capitalizeEveryWordFirstLetter,
    routeToPage,
  },
  props: {
    wishlist: {
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const getTotalSpotifyStreams = (tracks) => {
      let streams = 0;
      if (tracks.length) {
        for (const track of tracks) {
          streams += track.spotifyStreams ? track.spotifyStreams : 0;
        }
      }
      return formatNumberIntoHumanizeForm(streams);
    };

    const deleteWishlist = (wishlistID) => {
      Swal.fire({
        title: "Delete this Wishlist?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            await deleteUserWishlist(wishlistID);
            Swal.fire("Deleted!", "Wishlist has been deleted.", "success");
            await store.dispatch("WishlistModule/fetchWishlists");
            loading.value = false;
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire("Oops!", "Error in deleting wishlist.", "error");
        });
    };

    return {
      loading,
      deleteWishlist,
      getTotalSpotifyStreams,
    };
  },
};
</script>
